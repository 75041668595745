@font-face {
  font-family: 'NunitoSans-Regular';
  src: url('/build/fonts/NunitoSans-Regular.eot');
  src: url('/build/fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('/build/fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg'),
       url('/build/fonts/NunitoSans-Regular.ttf') format('truetype'),
       url('/build/fonts/NunitoSans-Regular.woff') format('woff'),
       url('/build/fonts/NunitoSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-ExtraBold';
    src: url('/build/fonts/NunitoSans-ExtraBold.eot');
    src: url('/build/fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/build/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg'),
    url('/build/fonts/NunitoSans-ExtraBold.ttf') format('truetype'),
    url('/build/fonts/NunitoSans-ExtraBold.woff') format('woff'),
    url('/build/fonts/NunitoSans-ExtraBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

